import { customElement, bindable, autoinject, computedFrom } from 'aurelia-framework';
import { InputBase } from '../inputBase';
import { Helpers } from 'application/helpers';

@customElement('form-input-text')
@autoinject()
export class FormInputText extends InputBase<string> {
    @bindable placeholder = '';
    @bindable disabled = false;
    @bindable disablePaste = false;
    @bindable multiline = false;
    @bindable currency = false;
    @bindable preventSpace = false;
    
    onPaste(e: any) {
        if (this.disablePaste) {
            e.preventDefault();
            return false;
        }

        return true;
    }

    onKeydown(e: KeyboardEvent) {
        if (this.preventSpace && Helpers.isSpaceKey(e)) {
            e.preventDefault();
            return false;
        }

        if (this.currency) {
            // allow comma and dot
            if (Helpers.isNavigationOrSelectionKey(e) || e.keyCode === 188 || e.keyCode === 190 || e.keyCode === 110 || e.key === "£" || e.key === "$" || e.key === "€") {
                return true;
            }
    
            if (!Helpers.isNumberKey(e)) {
                e.preventDefault();
                return false;
            }
        }

        return true;
    }

    @computedFrom('placeholder')
    get placeholderText() {
        return Helpers.isIE() ? '' : this.placeholder;
    }
}