import { customElement, bindable, useView, bindingMode, autoinject, containerless, PLATFORM, observable } from 'aurelia-framework';
import { InputBase } from '../inputBase';
import { CountryCodeType } from 'application/gql/createUpdateApplication.tsgql';
import { Helpers } from 'application/helpers';
import { Enums } from 'application/enums';

@customElement('form-input-telephone')
@autoinject()
export class FormInputTelephone extends InputBase<string> {
    @bindable placeholder = 'Landline or Mobile';
    @bindable({ defaultBindingMode: bindingMode.twoWay }) countryCode: CountryCodeType = CountryCodeType.UNITEDKINGDOM;

    @bindable disabled: boolean = false;

    countryCodes = Enums.countryCodes;

    onKeydown(e: KeyboardEvent) {
        if (Helpers.isNavigationOrSelectionKey(e) || Helpers.isSpaceKey(e)) {
            return true;
        }

        if (!Helpers.isNumberKey(e)) {
            e.preventDefault();
            return false;
        }

        return true;
    }
}
