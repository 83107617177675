import { customElement, bindable, useView, bindingMode, autoinject, containerless, PLATFORM, observable } from 'aurelia-framework';
import { InputBase } from '../inputBase';
import { Helpers } from 'application/helpers';

@customElement('form-input-radio')
@autoinject()
export class FormInputRadio extends InputBase<string> {
    @bindable({ defaultBindingMode: bindingMode.twoWay })  public value: string;
    @bindable dual = false;
    @bindable options: Array<any> = null;
    @bindable disabled = false;

    elem: HTMLElement;

    valueChanged(newVal: string, oldVal: string) {
        if (newVal != oldVal && !!this.elem) {
            Helpers.scrollToElement(this.elem);
        }
    }

    changeKeydown(event: KeyboardEvent) {
        return Helpers.keypressEnterOrSpace(event, () => this.value = '');
    }
}
