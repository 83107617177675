// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form-button button.with-icon {
  padding: 14px 40px 14px 24px;
}
form-button button:disabled {
  cursor: not-allowed;
}
form-button .icon-ctr {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 10px;
}
form-button .icon-ctr ex-icon {
  position: absolute;
  font-size: 1.6em;
  color: #666;
  top: -8px;
  left: 0;
}
form-button.red {
  margin-left: 10px;
}
form-button.red button:hover {
  background-color: rgb(255, 110, 110);
  color: #fff;
}
form-button.red button:hover ex-icon {
  color: #fff;
}
form-button.small {
  font-size: 0.85em;
}
form-button.small button {
  padding: 14px 25px;
  border-radius: 6px;
}
form-button.small button.with-icon {
  padding: 14px 20px 14px 5px;
}
form-button.small ex-icon {
  top: -4px;
  left: 7px;
}`, "",{"version":3,"sources":["webpack://./src/resources/elements/form-button/form-button.scss"],"names":[],"mappings":"AAEI;EACE,4BAAA;AADN;AAKE;EACE,mBAAA;AAHJ;AAME;EACE,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;AAJJ;AAMI;EACE,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,SAAA;EACA,OAAA;AAJN;AAQE;EACE,iBAAA;AANJ;AASM;EACE,oCAAA;EACA,WAAA;AAPR;AASQ;EACE,WAAA;AAPV;AAaE;EACE,iBAAA;AAXJ;AAaI;EACE,kBAAA;EACA,kBAAA;AAXN;AAaM;EACE,2BAAA;AAXR;AAeI;EACE,SAAA;EACA,SAAA;AAbN","sourcesContent":["form-button {\r\n  button {\r\n    &.with-icon {\r\n      padding: 14px 40px 14px 24px;\r\n    }\r\n  }\r\n\r\n  button:disabled{\r\n    cursor: not-allowed;\r\n  }\r\n\r\n  .icon-ctr {\r\n    position: relative;\r\n    display: inline-block;\r\n    width: 28px;\r\n    height:10px;\r\n\r\n    ex-icon {\r\n      position: absolute;\r\n      font-size: 1.6em;\r\n      color: #666;\r\n      top:-8px;\r\n      left:0;\r\n    }\r\n  }\r\n\r\n  &.red {\r\n    margin-left: 10px;\r\n\r\n    button {\r\n      &:hover {\r\n        background-color: rgb(255, 110, 110);\r\n        color: #fff;\r\n\r\n        ex-icon {\r\n          color: #fff;\r\n        }\r\n      }\r\n    }\r\n  }\r\n\r\n  &.small {\r\n    font-size: 0.85em;\r\n\r\n    button {\r\n      padding: 14px 25px;\r\n      border-radius: 6px;\r\n\r\n      &.with-icon {\r\n        padding: 14px 20px 14px 5px;\r\n      }\r\n    }\r\n\r\n    ex-icon {\r\n      top:-4px;\r\n      left:7px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
