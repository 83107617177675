import { customElement, useView, autoinject, PLATFORM, bindable } from 'aurelia-framework';

@customElement('form-button')
@useView(PLATFORM.moduleName('./control.html'))
@autoinject()
export class FormButton {
    @bindable public label: string = "";
    @bindable disabled = false;
    @bindable icon: string;
    @bindable clickAction: any;

    clicked() {
        if (this.clickAction && !this.disabled) {
            this.clickAction();
        }
    }
}
