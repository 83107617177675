import { customElement, bindable, useView, bindingMode, autoinject, containerless, PLATFORM, observable, BindingEngine } from 'aurelia-framework';
import { InputBase } from '../inputBase';
import { Helpers } from 'application/helpers';

@customElement('form-input-multiple')
@autoinject()
export class FormInputMultiple extends InputBase<string[]> {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) public value: string[];
  @bindable dual = false;
  @bindable options: Array<any> = null;
  @bindable disabled = false;

  elem: HTMLElement;

  values: object = {};

  constructor(
    private bindingEngine: BindingEngine) {
    super();
  }

  bind() {
    if (this.value === null || this.value === undefined) {
      this.value = [];
    } else {
      this.value.forEach(v => {
        this.values[v] = true;
      })
    }

    for (let i = 0; i < this.options.length; i++) {
      const optionName = this.options[i];
      this.bindingEngine
        .propertyObserver(this.values, optionName)
        .subscribe((newValue, oldValue) => {
          const set = new Set(this.value);
          if (newValue) {
            set.add(optionName);
          } else {
            set.delete(optionName);
          }
          this.value = Array.from(set);
        });
    }
  }

  valueChanged(newVal: string, oldVal: string) {
    if (newVal != oldVal && !!this.elem) {
      Helpers.scrollToElement(this.elem);
    }
  }
}
