import { customElement, bindable, bindingMode, autoinject, observable } from 'aurelia-framework';
import { InputBase } from '../inputBase';
import { PostcodeLookupClient, AddressDtoResult } from 'application/gql/postcodeLookup.tsgql';
import { validate, ValidateNested, IsNotEmpty, IsOptional, IsPostcode, ValidateIf } from "validation";
import { FormInputText } from '../form-input-text/form-input-text';
import { AddressDtoInput, CountryCodeType } from 'application/gql/createUpdateApplication.tsgql';
import { Update } from '../../../updateService';
import { Helpers } from 'application/helpers';
import { FormInputCountry } from '../form-input-country/form-input-country';
import { Enums } from '../../../application/enums';

@customElement('form-input-address')
@autoinject()
export class FormInputAddress extends InputBase<Address> {
    constructor(private postcodeLookupClient: PostcodeLookupClient) {
        super();
    }

    @ValidateNested()
    @bindable({ defaultBindingMode: bindingMode.twoWay })  public value: Address;

    @bindable label: string;
    @bindable sameAs: Address;
    @bindable sameAsText = "Same as the property address";

    @bindable disabled: boolean = false;
    @bindable addressRequired: boolean = true;

    manual: boolean;
    addressConfirmed: boolean;
    searchResults: AddressDtoResult[] = [];
    noResults: boolean;

    countryCodes = Enums.countryCodes;

    address1VM: FormInputText;
    townVM: FormInputText;
    postcodeVM: FormInputText;
    countryVM: FormInputCountry;

    searching = false;

    @IsPostcode({ message: "Please enter a valid postcode" })
    @ValidateIf((o: FormInputAddress) => o.addressRequired || !!o.lookupPostcode)
    @observable lookupPostcode: string;

    bind() {
        if (!!this.value && this.value.address1 && this.value.town && this.value.postcode && this.value.country) {
            this.addressConfirmed = true;
        }
    }

    async lookupPostcodeChanged() {
        const errors = await validate(this);
        if (this.lookupPostcode && !errors.some(x => x.property === 'lookupPostcode')) {
            this.searching = true;
            try {
                const result = await this.postcodeLookupClient.postcodeLookup(this.lookupPostcode);
                this.searchResults = result.postcodeLookup;
            } catch {
                this.searchResults = [];
            } finally {
                this.noResults = !this.searchResults || this.searchResults.length === 0;
                this.searching = false;
            }
        }
        else {
            this.searching = false;
            this.searchResults = [];
            this.noResults = false;
        }
    }

    getCountryCodeDescription(countryCode: CountryCodeType) {
        return countryCode && this.countryCodes.find(c => c.id === countryCode)?.description;
    }

    getAddressString(address: AddressDtoResult) {
        return [address.address1, address.address2, address.address3, address.town, address.postcode, this.getCountryCodeDescription(address.country)].filter(x => !!x).join(', ');
    }

    selectAddress(address: AddressDtoResult) {
        this.value.address1 = address.address1;
        this.value.address2 = address.address2;
        this.value.address3 = address.address3;
        this.value.town = address.town;
        this.value.postcode = address.postcode;
        this.value.country = address.country;
        this.addressConfirmed = true;
    }

    public confirmAddress() {
        this.manual = true;

        // timeout ensures vm's are available
        setTimeout(async () => {
            this.address1VM.markVisited();
            this.townVM.markVisited();
            this.postcodeVM.markVisited();
            this.countryVM.markVisited();
            const errors = await validate(this.value);
            this.addressConfirmed = errors.length === 0;    
        }, 0);
    }

    reset(manualMode: boolean) {
        this.searchResults = [];
        this.manual = manualMode;
    }

    searchResultKeyup(event: KeyboardEvent, address: AddressDtoResult) {
        return Helpers.keypressEnterOrSpace(event, () => this.selectAddress(address));
    }

    changeAddressKeydown(event: KeyboardEvent) {
        return Helpers.keypressEnterOrSpace(event, () => this.addressConfirmed = false);
    }

    enterManuallyKeydown(event: KeyboardEvent) {
        return Helpers.keypressEnterOrSpace(event, () => this.reset(true));
    }

    searchByPostcodeKeydown(event: KeyboardEvent) {
        return Helpers.keypressEnterOrSpace(event, () => this.manual = false);
    }

    setAddressToSameAs() {
        if (!this.value) {
            this.value = new Address();
        }
        
        this.value.address1 = this.sameAs.address1;
        this.value.address2 = this.sameAs.address2;
        this.value.address3 = this.sameAs.address3;
        this.value.town = this.sameAs.town;
        this.value.postcode = this.sameAs.postcode;
        this.value.country = this.sameAs.country;
        this.confirmAddress();
    }
}

export class Address {
    @Update.IgnoreUpdates()
    sameAsPropertyAddress = false;

    @IsNotEmpty({ message: "Please enter address line 1" })
    address1: string;
    
    @IsOptional()
    address2: string;
    
    @IsOptional()
    address3: string;
    
    @IsNotEmpty({ message: "Please enter a town" })
    town: string;
    
    @IsPostcode({ message: "Please enter a valid postcode" })
    @ValidateIf((a: Address) => a.country === CountryCodeType.UNITEDKINGDOM)
    postcode: string;
    
    @IsNotEmpty({ message: "Please select a country" })
    country: CountryCodeType = CountryCodeType.UNITEDKINGDOM;

    static fromDto(address: AddressDtoResult) {
        const result = new Address();

        if (address) {
            result.address1 = address.address1;
            result.address2 = address.address2;
            result.address3 = address.address3;
            result.town = address.town;
            result.postcode = address.postcode;
            result.country = address.country;
        }

        return result;
    }

    toDto() {
        const result = new AddressDtoInput();
        result.address1 = this.address1;
        result.address2 = this.address2;
        result.address3 = this.address3;
        result.town = this.town;
        result.postcode = this.postcode;
        result.country = this.country;
        return result;
    }

    equals(address: Address) {
        return this.address1 === address.address1 &&
            this.address2 === address.address2 &&
            this.address3 === address.address3 &&
            this.town === address.town &&
            this.postcode === address.postcode &&
            this.country === address.country;
    }
}
