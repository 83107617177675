import { customElement, bindable, bindingMode, autoinject, computedFrom, Disposable, BindingEngine } from 'aurelia-framework';
import { InputBase } from '../inputBase';
import { IsNotEmpty, ValidateIf, IsEmail, ValidateNested, IsOptional, ValidationArguments, Validate, IsNotBlank, ValidationOptions, registerDecorator, Validator, EqualsPropertyConstraint, validate, isPhoneNumber } from 'validation';
import { Address, FormInputAddress } from '../form-input-address/form-input-address';
import { Update } from 'updateService';
import { CountryCodeType } from 'application/gql/retrieveApplication.tsgql';
import { Lists } from 'application/lists';
import { Helpers } from 'application/helpers';
import { ContactDtoInput } from 'application/gql/createUpdateApplication.tsgql';
import { CurrentUser } from 'user';

@customElement('form-input-contact')
@autoinject()
export class FormInputContact extends InputBase<Contact> {
    constructor(private user: CurrentUser, private bindingEngine: BindingEngine) {
        super();
    }

    @Update.UpdateNested()
    @ValidateNested()
    @bindable({ defaultBindingMode: bindingMode.twoWay })  public value: Contact;
    
    @bindable contactTypeLabel = 'Company or a private individual?';
    @bindable contactNumberLabel = 'Contact number';
    @bindable reporter: boolean = false;
    @bindable policyholder: boolean = false;
    @bindable additionalPolicyholder: boolean = false;
    @bindable captureRelationship: boolean = false;
    @bindable captureAddress: boolean = false;
    @bindable saved: boolean = false;
    @bindable representative: boolean = false;
    @bindable isFreeholder: boolean = false;
    @bindable isManagingAgent: boolean = false;
    @bindable disabled: boolean = false;

    titles = Lists.titles;
    relationships = Lists.relationships;
    additionalPolicyholderRelationships = Lists.additionalPolicyholderRelationships;
    representativeRelationships = Lists.representativeRelationships;
    familyRelationships = Lists.familyRelationships;
    subscriptions: Disposable[] = [];
    addressVM: FormInputAddress;

    attached() {
        if (this.isManagingAgent) {
            this.subscriptions.push(this.bindingEngine.propertyObserver(this.value, 'telephone').subscribe(async () => { await validate(this.addressVM); }));
            this.subscriptions.push(this.bindingEngine.propertyObserver(this.value, 'email').subscribe(async () => { await validate(this.addressVM); }));
            this.subscriptions.push(this.bindingEngine.propertyObserver(this.value.address, 'postcode').subscribe(async () => { await validate(this); }));
        }
    }

    detached() {
        this.subscriptions.forEach(x => x.dispose());
    }

    bind() {
        if (this.value) {
            this.value.reporter = this.reporter;
            this.value.policyholder = this.policyholder;
            this.value.additionalPolicyholder = this.additionalPolicyholder;
            this.value.captureRelationship = this.captureRelationship;
            this.value.captureAddress = this.captureAddress;
            this.value.representative = this.representative;
            this.value.admin = this.user.isAdmin;
            this.value.isFreeholder = this.isFreeholder;
            this.value.isManagingAgent = this.isManagingAgent;
        }
    }
}

export class Contact {
    constructor(leadClaimant: boolean = null) {
        this.isLeadClaimant = leadClaimant;
    }

    id: string;
    editing = true;
    reporter = false;
    policyholder = false;
    additionalPolicyholder = false;
    captureRelationship = false;
    captureAddress = false;
    representative = false;
    isFreeholder = false;
    isManagingAgent = false;
    admin = false;

    @Update.IgnoreUpdates()
    @computedFrom('title', 'titleOther', 'firstname', 'surname')
    get name() {
        return `${this.title === 'Other' ? this.titleOther : this.title || ''} ${this.firstname || ''} ${this.surname || ''}`.trim();
    }

    @IsNotEmpty({ message: "Please select Company or Private Individual" })
    contactType: string;

    @IsNotEmpty({ message: "Please enter title" })
    @ValidateIf((o: Contact) => o.contactType === "Private Individual" || o.reporter || o.representative)
    title: string;

    @IsNotBlank({ message: "Please enter title" })
    @ValidateIf((o: Contact) => (o.contactType === "Private Individual" || o.reporter || o.representative) && o.title === "Other")
    titleOther: string;

    @IsNotBlank({ message: "Please enter first name" })
    @ValidateIf((o: Contact) => o.contactType === "Private Individual" || o.reporter || o.representative)
    firstname: string;
    
    @IsNotBlank({ message: "Please enter surname" })
    @ValidateIf((o: Contact) => o.contactType === "Private Individual" || o.reporter || o.representative)
    surname: string;
    
    @IsNotBlank({ message: "Please enter company name" })
    @ValidateIf((o: Contact) => o.contactType === "Company")
    companyName: string;
    
    @IsOptional()
    telephoneCountryCode: CountryCodeType = CountryCodeType.UNITEDKINGDOM;

    @IsTelephone({ message: "Please enter a valid phone number" })
    @ValidateIf((o: Contact) => o.telephoneRequired || !!o.telephone)
    telephone: string;
    
    @IsEmail({}, { message: "Please enter a valid email address" })
    @ValidateIf((o: Contact) => o.emailRequired || !!o.email)
    email: string;

    @Validate(EqualsPropertyConstraint, ['email'], { message: "Email addresses do not match" })
    @ValidateIf((o: Contact) => o.reporter && o.correspondenceMethod !== "Post")
    emailConfirmation: string;
    
    @IsNotEmpty({ message: "Please select Yes or No" })
    @ValidateIf((o: Contact) => o.reporter)
    isLeadClaimant: boolean;

    @IsNotEmpty({ message: "Please select relationship type" })
    @ValidateIf((o: Contact) => o.captureRelationship && !!o.contactType && !o.isLeadClaimant)
    relationshipToLeadClaimant: string;
    
    @IsNotEmpty({ message: "Please enter relationship type" })
    @ValidateIf((o: Contact) => o.captureRelationship && !!o.contactType && !o.isLeadClaimant && o.relationshipToLeadClaimant === "Other")
    relationshipToLeadClaimantOther: string;
    
    @IsNotEmpty({ message: "Please select relationship type" })
    @ValidateIf((o: Contact) => o.captureRelationship && !!o.contactType && !o.isLeadClaimant && o.relationshipToLeadClaimant === "Family Member")
    relationshipToLeadClaimantFamilyMember: string;
    
    @IsNotEmpty({ message: "Please enter relationship type" })
    @ValidateIf((o: Contact) => o.captureRelationship && !!o.contactType && !o.isLeadClaimant && o.relationshipToLeadClaimantFamilyMember === "Other")
    relationshipToLeadClaimantFamilyMemberOther: string;

    @Update.UpdateNested()
    @ValidateNested()
    @ValidateIf((o: Contact) => o.addressRequired)
    address: Address = new Address();

    @IsNotEmpty({ message: "Please select Email or Post" })
    correspondenceMethod: string = "Email";

    @Update.IgnoreUpdates()
    @computedFrom('reporter', 'policyholder', 'additionalPolicyholder', 'representative', 'isManagingAgent', 'telephone', 'email', 'address.postcode')
    get telephoneRequired() {
        if (this.isManagingAgent) {
            return (!this.email && !this.address.postcode) || !!this.telephone;
        }

        return this.reporter || this.policyholder || this.additionalPolicyholder || this.representative;
    }

    @Update.IgnoreUpdates()
    @computedFrom('reporter', 'correspondenceMethod', 'representative', 'isManagingAgent', 'telephone', 'email', 'address.postcode')
    get emailRequired() {
        if (this.isManagingAgent) {
            return (!this.telephone && !this.address.postcode) || !!this.email;
        }

        return (this.reporter && this.correspondenceMethod !== "Post") || this.representative;
    }
    
    @Update.IgnoreUpdates()
    @computedFrom('captureAddress', 'admin', 'correspondenceMethod', 'isFreeholder', 'isManagingAgent', 'telephone', 'email', 'address.postcode')
    get addressRequired() {
        if (this.isFreeholder) {
            return false;
        }

        if (this.isManagingAgent) {
            return (!this.telephone && !this.email) || !!this.address.postcode;
        }

        return this.captureAddress || (this.admin && this.correspondenceMethod === "Post");
    }

    static fromDto(contact: any, additionalPolicyholder = false, representative = false) {
        const result = new Contact();

        if (contact) {
            result.id = contact.id;
            result.contactType = contact.contactType;
            result.email = contact.email;
            result.emailConfirmation = contact.email;
            result.contactType = contact.contactType;
            result.title = Helpers.handleOther(Lists.titles, contact.title);
            result.titleOther = Helpers.handleOther(Lists.titles, contact.title, true);
            result.firstname = contact.firstname;
            result.surname = contact.surname;
            result.companyName = contact.companyName;
            result.telephoneCountryCode = contact.telephoneCountryCode;
            result.telephone = contact.telephone;
            result.isLeadClaimant = contact.isLeadClaimant;
            result.address = Address.fromDto(contact.address);
            result.correspondenceMethod = contact.correspondenceMethod;
            
            if (!!contact.relationshipToLeadClaimant) {
                const relationships = additionalPolicyholder ? Lists.additionalPolicyholderRelationships :
                                      representative ? Lists.representativeRelationships : Lists.relationships;

                result.relationshipToLeadClaimant = Helpers.handleOther(relationships, contact.relationshipToLeadClaimant);
                result.relationshipToLeadClaimantOther = Helpers.handleOther(relationships, contact.relationshipToLeadClaimant, true);
            }

            if (!!contact.relationshipToLeadClaimantFamilyMember) {
                const familyRelationships = additionalPolicyholder || representative ? [] : Lists.familyRelationships;

                result.relationshipToLeadClaimantFamilyMember = Helpers.handleOther(familyRelationships, contact.relationshipToLeadClaimantFamilyMember);
                result.relationshipToLeadClaimantFamilyMemberOther = Helpers.handleOther(familyRelationships, contact.relationshipToLeadClaimantFamilyMember, true);
            }
        }

        return result;
    }

    toDto() {
        const result = new ContactDtoInput();
        result.id = this.id;
        result.email = this.email;
        result.contactType = this.contactType;
        result.title = this.title === "Other" ? this.titleOther : this.title;
        result.firstname = this.firstname;
        result.surname = this.surname;
        result.companyName = this.companyName;
        result.telephoneCountryCode = this.telephoneCountryCode;
        result.telephone = this.telephone;
        result.isLeadClaimant = this.isLeadClaimant;
        result.relationshipToLeadClaimant = this.relationshipToLeadClaimant === "Other" ? this.relationshipToLeadClaimantOther : this.relationshipToLeadClaimant;
        result.relationshipToLeadClaimantFamilyMember = this.relationshipToLeadClaimantFamilyMember === "Other" ? this.relationshipToLeadClaimantFamilyMemberOther : this.relationshipToLeadClaimantFamilyMember;
        result.address = this.address.toDto();
        result.correspondenceMethod = this.correspondenceMethod;
        return result;
    }
}

export function IsTelephone(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: "isTelephone",
            target: object.constructor,
            propertyName: propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value: string, args: ValidationArguments) {
                    const contact = args.object as Contact;
                    if (contact.telephoneCountryCode === CountryCodeType.UNITEDKINGDOM) {
                        return isPhoneNumber(value, 'GB');
                    }
                    else {
                        return value && /^[0-9 ]+$/.test(value.trim());
                    }
                }
            }
        });
    };
}
