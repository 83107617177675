import { customElement, bindable, autoinject } from 'aurelia-framework';
import { InputBase } from '../inputBase';
import { CountryCodeType } from 'application/gql/createUpdateApplication.tsgql';
import { Helpers } from 'application/helpers';
import { Enums } from 'application/enums';

@customElement('form-input-country')
@autoinject()
export class FormInputCountry extends InputBase<CountryCodeType> {
    @bindable disabled: boolean = false;

    countryCodes = Enums.countryCodes;

    onKeydown(e: KeyboardEvent) {
        if (Helpers.isNavigationOrSelectionKey(e) || Helpers.isSpaceKey(e)) {
            return true;
        }

        if (!Helpers.isNumberKey(e)) {
            e.preventDefault();
            return false;
        }

        return true;
    }
}
