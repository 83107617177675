// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=text].tel {
  display: inline-block;
  width: calc(50% - 14px);
}

select.tel {
  display: inline-block;
  width: 50%;
  margin-right: 9px;
}`, "",{"version":3,"sources":["webpack://./src/resources/elements/form-input-telephone/form-input-telephone.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,uBAAA;AACJ;;AACA;EACI,qBAAA;EACA,UAAA;EACA,iBAAA;AAEJ","sourcesContent":["input[type=\"text\"].tel {\r\n    display: inline-block;\r\n    width:calc(50% - 14px);\r\n}\r\nselect.tel {\r\n    display: inline-block;\r\n    width:50%;\r\n    margin-right: 9px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
