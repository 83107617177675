// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-results {
  margin: 0 0 10px 0;
  max-height: 320px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.search-results div {
  background: #fff;
  padding: 15px;
  margin: 3px;
  cursor: pointer;
}
.search-results div:hover {
  background: #fefefe;
}

h4 span {
  font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/resources/elements/form-input-address/form-input-address.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,iCAAA;AACJ;AACI;EACI,gBAAA;EACA,aAAA;EACA,WAAA;EACA,eAAA;AACR;AACQ;EACI,mBAAA;AACZ;;AAIA;EACE,mBAAA;AADF","sourcesContent":[".search-results {\r\n    margin: 0 0 10px 0;\r\n    max-height: 320px;\r\n    overflow: auto;\r\n    overflow-x: hidden;\r\n    overflow-y: auto;\r\n    -webkit-overflow-scrolling: touch;\r\n\r\n    div {\r\n        background: #fff;\r\n        padding: 15px;\r\n        margin: 3px;\r\n        cursor: pointer;\r\n\r\n        &:hover {\r\n            background: #fefefe;\r\n        }\r\n    }\r\n}\r\n\r\nh4 span {\r\n  font-weight: normal;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
