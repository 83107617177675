import { customElement, bindable, useView, bindingMode, autoinject, containerless, PLATFORM, observable } from 'aurelia-framework';
import { InputBase } from '../inputBase';
import * as moment from 'moment'
import { Helpers } from 'application/helpers';

@customElement('form-input-date')
@autoinject()
export class FormInputDate extends InputBase<Date> {
    @bindable showDay = true;
    @observable day: string;
    @observable month: string;
    @observable year: string;

    @bindable disabled: boolean = false;
    
    bound = false;
    focusMonth = false;
    focusYear = false;

    dayChanged() {
        if (this.bound) {
            if (this.day && !isNaN(Number(this.day)) && this.day.length === 2) {
                this.focusMonth = true;
            }
        
            this.dateChanged();
        }
    }

    monthChanged() {
        if (this.bound) {
            if (this.month && !isNaN(Number(this.month)) && this.month.length === 2) {
                this.focusYear = true;
            }
        
            this.dateChanged();
        }
    }

    yearChanged() {
        if (this.bound) {
            this.dateChanged();
        }
    }

    dateChanged() {
        if (this.year && this.year.length === 4 && !isNaN(Number(this.day)) && !isNaN(Number(this.month)) && !isNaN(Number(this.year))) {
            const d = this.day.length === 1 ? `0${this.day}` : this.day;
            const m = this.month.length === 1 ? `0${this.month}` : this.month;
            const dateString = `${this.year}-${m}-${d}T00:00:00.000Z`;
            const date = moment(dateString);
            if (date.isValid()) {
                this.value = date.toDate();
                return;
            }
        }
        
        this.value = null;
    }

    bind() {
        if (!!this.value) {
            const date = moment(this.value);
            this.day = date.format('DD');
            this.month = date.format('MM');
            this.year = date.format('YYYY');
        }
        else {
            this.day = !this.showDay ? '01' : '';
            this.month = '';
            this.year = '';
        }

        this.bound = true;
    }

    onKeydown(e: KeyboardEvent) {
        if (Helpers.isNavigationOrSelectionKey(e)) {
            return true;
        }

        if (!Helpers.isNumberKey(e)) {
            e.preventDefault();
            return false;
        }

        return true;
    }
}
