import { children } from 'aurelia-framework';
import { bindable, observable, bindingMode, inject, TaskQueue, autoinject } from 'aurelia-framework';
import { BindingEngine, computedFrom } from 'aurelia-binding';

declare var $;
let _counter: number = 0;

@autoinject
export class CrispPicker {
    constructor(private bindingEngine: BindingEngine, private taskQueue: TaskQueue) {
        _counter += 1;
        this.counter = _counter;
        this.id = 'crisp-picker-' + this.counter;
    }

    counter: number = 0;
    id: string;
    @bindable label = '';
    @bindable items = [];
    @observable({ changeHandler: 'createSelect' })
    private select: Element;
    @bindable multiple = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    selected = null;
    @bindable idField = 'id';
    @bindable descriptionField = 'description';
    @bindable valueField;
    @bindable({ changeHandler: 'createSelect' })
    enabled = true;
    @bindable canClear: boolean = false;

    @bindable valid = true;
    @bindable error = '';

    attached() {
        this.createSelect();
    }

    // when we bind in a new array from outside the change event stops working, so instead here
    // when we detect an array change (a new array) we setup an observer of our own
    _itemsSubscription = null;
    itemsChanged(n, o) {
        if (this._itemsSubscription) {
            this._itemsSubscription.dispose();
        }
        if (n !== undefined && n !== null) {
            this._itemsSubscription = this.bindingEngine.collectionObserver(n).subscribe(_ => {
                this.createSelect();
            });
        }
        this.createSelect();
    }

    // we need to watch the selected aray using the observer
    // to catch any changes made, so we can update the text label
    _selectionSubscription = null;
    selectedChanged(n, o) {
        if (n === undefined || n === null) {
            $(this.select).val(null);
            this.getInnerInput().val(null);
            return;
        }
        if (this._selectionSubscription) {
            this._selectionSubscription.dispose();
        }
        if (n instanceof Array) {
            this._selectionSubscription = this.bindingEngine.collectionObserver(n).subscribe(_ => {
                this.updateMulti(this.selected);
            });
            this.updateMulti(this.selected);
        } else {
            // if somehow no items - clear the selected text
            if (this.items === undefined || this.items === null) {
                console.warn(`there are no items in the collection - label = ${this.label}`);
                this.getInnerInput().val('');
                return;
            }
            const value = this.valueField ? n : this.getId(n) || '';
            $(this.select).val(value.toString());
            let description = '';
            if (this.valueField) {
                const item = this.items.find(i => i[this.valueField] === n);
                if (item) description = item[this.descriptionField].toString();
            } else {
                description = n.description;
            }
            this.getInnerInput().val(description);
        }
    }

    private updateMulti(newVals: any[]) {
        let valsAndDescs: any[];
        if (this.items === undefined || this.items === null) {
            console.warn(`there are no items in the collection - label = ${this.label}`);
            valsAndDescs = [];
        } else {
            if (this.valueField) {
                valsAndDescs = newVals.map(x => {
                    const itemIndex = this.items.findIndex(item => item[this.valueField] === x);
                    const item = this.items[itemIndex];
                    return {
                        index: itemIndex,
                        val: x.toString(),
                        desc: item ? item[this.descriptionField] : ''
                    };
                });
            } else {
                valsAndDescs = newVals
                    .map(x => {
                        const id = this.getId(x);
                        const itemIndex = this.items.findIndex(item => this.getId(item) === id);
                        const item = this.items[itemIndex];
                        return {
                            index: itemIndex,
                            val: id,
                            desc: item ? item[this.descriptionField] : '-unrecognised-'
                        };
                    })
                    .filter(x => x != null);
            }
        }

        const innerInput = this.getInnerInput();

        // update the select so the listed value are correct
        $(this.select).val(valsAndDescs.map(x => x.val));
        innerInput.val(
            valsAndDescs
                .map(x => x.desc)
                .join(', ')
                .trim()
        );

        // update the checkboxes internal to th ul so the checked reflect the selected correctly
        const lis = innerInput.siblings('ul').children();
        innerInput
            .siblings('ul')
            .find("input[type='checkbox']")
            .prop('checked', function(i, val) {
                return valsAndDescs.findIndex(m => m.index === i) !== -1;
            });
    }

    detached() {
        if (this._itemsSubscription) {
            this._itemsSubscription.dispose();
        }
        if (this._selectionSubscription) {
            this._selectionSubscription.dispose();
        }
    }

    createSelect() {
        this.taskQueue.queueTask({
            call: () => {
                const $select = $(this.select);
                $select.material_select('destroy');

                if (this.enabled) $select.removeClass('disabled').removeAttr('disabled');
                else $select.addClass('disabled').prop('disabled', true);

                $select.material_select();

                const innerInput = this.getInnerInput();

                // HACK: This only affects single selection. The event below in materialize closes the dropdown before the 'click' event
                // when we position the drop down list. So here we remove the event, then in itemsSelected() we manually call the 'close' event
                // when a single item is selected.
                //
                // $newSelect.on('blur', function() {
                //    if (!multiple) {
                //      $(this).trigger('close');
                //    }
                //    ...
                // });
                if (!this.multiple) {
                    innerInput.off('blur');
                }

                // so that the popup window that displays the list does not show inside it's parent container and cause that container to overflow
                // we instead move the popup to the body for display.
                innerInput.on('open', () => {
                    const popupList = innerInput.siblings('ul');
                    // store the current instance id against the popup list so we can
                    // match it back later when trying to move it back
                    popupList.data('id', this.id);

                    const currentPosition = innerInput.offset();
                    const body = $('body');

                    const dropdownHeight = 300;
                    const bottomEdge = body.innerHeight();

                    // detect if we will go offscreen at the bottom - in which case make the options appear above
                    if (currentPosition.top + dropdownHeight > bottomEdge) {
                        // going offscreen at bottom;
                        if (currentPosition.top - dropdownHeight < 0) {
                            // still going offscreen if we flow up - so resize instead;
                            popupList.css('max-height', bottomEdge - currentPosition.top);
                        } else {
                            // flow upwards;
                            currentPosition.top = currentPosition.top - dropdownHeight + innerInput.height();
                        }
                    }

                    popupList.css('display', 'none');
                    body.append(popupList);

                    setTimeout(
                        () =>
                            popupList.css({
                                position: 'absolute',
                                left: currentPosition.left,
                                top: currentPosition.top,
                                display: 'block'
                            }),
                        200
                    );

                    $select.on('change.crisp-picker-' + this.id, () => {
                        this.itemsSelected($select.val());
                    });
                    $select.on('blur.crisp-picker-' + this.id, () => {
                        this.itemsSelected($select.val());
                    });

                    // we need to move the popup back to its original position in the tree when user clicks away
                    $(document).on('click.crisp-picker-' + this.id, x => {
                        this.tryMovePopupListFromBodyBackToInput();
                    });
                });

                innerInput.on('close', () => {
                    // we need to move the popup back to it's original position in the tree when user makes a selection
                    this.tryMovePopupListFromBodyBackToInput();
                    $select.off('.crisp-picker-' + this.id);
                });

                this.selectedChanged(this.selected, null);
            }
        });
    }

    tryMovePopupListFromBodyBackToInput() {
        // if the dropdown for this instance is currently open
        // get it and move it back. if a different ul is there leave it alone
        const existing = $('body>ul.select-dropdown')
            .toArray()
            .map(f => $(f))
            .find(f => f.data('id') === this.id);
        if (!!existing) {
            this.getInnerInput()
                .parent()
                .append(existing.css({ position: 'absolute', display: 'none' }));
            // once we have moved it back we aren't going to need the event watcher so remove it
            $(document).off('click.crisp-picker-' + this.id);
        }
    }

    itemsSelected = (selected: string | string[]) => {
        if (!selected) {
            this.selected = this.multiple ? [] : null;
            return;
        }
        if (typeof selected === 'string') {
            this.selected = this.getValue(this.items.find(x => this.getId(x).toString() === selected));
        } else {
            this.selected = this.items
                .filter(x => selected.indexOf(this.getId(x).toString()) >= 0)
                .map(x => this.getValue(x));
        }

        // HACK: see comment in createSelect() to see why we need this
        if (!this.multiple) {
            this.getInnerInput().trigger('close');
        }

        this.select.dispatchEvent(new CustomEvent('selected', { bubbles: true, detail: this.selected }));
    };

    getId(item) {
        if (!item) return item;
        if (this.idField) return item[this.idField];
        return item;
    }

    getDescription(item) {
        if (!item) return item;
        return item[this.descriptionField];
    }

    getValue(item) {
        if (!item) return item;
        if (this.valueField) return item[this.valueField];
        return item;
    }

    private getInnerInput() {
        return $(this.select).siblings('input.select-dropdown');
    }

    @computedFrom('selected', 'multiple', 'canClear')
    get showClear() {
        if ((this.selected !== 0 && !!this.selected === false) || this.multiple === true) return false;
        return this.canClear === true;
    }

    clear() {
        if (this.multiple === true) return;
        this.selected = null;
    }
}
