import { bindable, bindingMode, customElement, computedFrom } from "aurelia-framework";

let _counter: number = 0;

@customElement('crisp-input-radio')
export class CrispInputRadio {
    constructor() {
        _counter += 1;
        this.counter = _counter;
        this.id = "crisp-input-radio-group-" + this.counter;
    }

    yesNoOptions = [
        { id: true, description: 'Yes', },
        { id: false, description: 'No', }
    ];

    counter: number = 0;
    id: string;

    @bindable label: string;
    @bindable items: Array<any> = null;
    @bindable descriptionField: string = 'description';
    @bindable valueField;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: any;
    @bindable enabled = true;
    @bindable yesNoItems: boolean = false;
    @bindable error: string;

    radioOptions: Array<RadioOption> = null;

    private _selected: RadioOption;

    yesNoItemsChanged() {
        if (this.yesNoItems == true && !this.items) {
            this.items = this.yesNoOptions;
            this.valueField = "id";
            this.itemsChanged(this.items, null)
        }
    }

    @computedFrom('_selected')
    get selected(): RadioOption {
        return this._selected;
    }

    set selected(selectedOption: RadioOption) {
        this._selected = selectedOption;
        this.value = selectedOption.value;
    }


    itemsChanged(n, o) {
        if (this.items) {
            this.radioOptions = this.items.map(x => {
                return new RadioOption(x, this.descriptionField, this.valueField);
            });
            this._selected = this.radioOptions.find(x => x.value === this.value);
        }
    }

    /// if the bound value changes we need to update _selected so it's reflected in the radio buttons
    valueChanged(n, o) {
        if (this.radioOptions) {
            this._selected = this.radioOptions.find(x => x.value === this.value);
        }
    }
}

let _optionCounter: number = 0;
export class RadioOption {
    constructor(choice: any, labelProperty: string, valueProperty: string) {
        if (typeof choice === "object") {
            this.label = choice[labelProperty];
        } else {
            this.label = choice;
        }

        if (typeof choice === "object" && valueProperty) {
            this.value = choice[valueProperty];
        } else {
            this.value = choice;
        }

        _optionCounter += 1;
        this.id = "crisp-input-radio-group-" + _counter + "-option-" + _optionCounter;
    }

    id: string;
    label: string;
    value: any;
}
