// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form-input-multiple .answer-val {
  padding-right: 70px;
}
form-input-multiple .answer-val a.change {
  margin-right: -55px;
}
form-input-multiple .answer-disabled {
  background-color: rgb(235, 235, 228);
}`, "",{"version":3,"sources":["webpack://./src/resources/elements/form-input-multiple/form-input-multiple.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAEI;EACE,mBAAA;AAAN;AAME;EACE,oCAH6B;AADjC","sourcesContent":["form-input-multiple {\r\n  .answer-val {\r\n    padding-right: 70px;\r\n\r\n    a.change {\r\n      margin-right: -55px;\r\n    }\r\n  }\r\n\r\n  $form-input-multiple-bg-color: rgb(235, 235, 228);\r\n\r\n  .answer-disabled {\r\n    background-color: $form-input-multiple-bg-color\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
