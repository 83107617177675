// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form-input-bool .answer-val {
  padding-right: 70px;
}
form-input-bool .answer-disabled {
  background-color: rgb(235, 235, 228);
}`, "",{"version":3,"sources":["webpack://./src/resources/elements/form-input-bool/form-input-bool.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAGE;EACE,oCAAA;AADJ","sourcesContent":["form-input-bool {\r\n  .answer-val {\r\n    padding-right: 70px;\r\n  }\r\n\r\n  .answer-disabled {\r\n    background-color: rgb(235, 235, 228)\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
