import { customElement, bindable, bindingMode, autoinject, observable, computedFrom, BindingEngine } from 'aurelia-framework';
import { InputBase } from '../inputBase';
import { Document } from '../../../application/components/evidence-item';
import { BindingSignaler } from 'aurelia-templating-resources';
import { HttpClient, Interceptor, HttpResponseMessage, RequestMessage } from 'aurelia-http-client';
import { Router } from 'aurelia-router';
import { validate } from 'validation';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Helpers } from 'application/helpers';
import { DocumentDownloadService } from '../../../services/documentDownloadService';
import { TokenService } from '../../../services/tokenService';

@customElement('form-input-file')
@autoinject()
export class FormInputFile extends InputBase<Document[]> {

    constructor(
        private bindingSignaler: BindingSignaler,
        private httpClient: HttpClient,
        private router: Router,
        private aggregator: EventAggregator,
        private documentDownloadService: DocumentDownloadService,
        private tokenService: TokenService) {
        super();
    }

    @bindable({ defaultBindingMode: bindingMode.twoWay }) existingFiles: Document[];
    fileInputFiles: FileList;
    fileInputValue: any;
    dragOver = false;
    searchText: string;
    parent: any;
    fileSelectionError: string = '';
    showExisting: boolean = false;

    @bindable disabled: boolean = false;

    readonly acceptedFileTypes = '.pdf, .doc, .docx, .xlsx, .xls, .pptx, .ppt, .jpg, .jpeg, .tif, .tiff, .gif, .bmp, .png, .dwg, .mpg, .mov, .avi, .mp4, .html, .msg, .txt, .m4a, .mp3, .wav';
    readonly maxFileSize = 1073741824; // 1GB

    bind(bindingContext: any) {
        this.parent = bindingContext;

        // remove invalid files which may have been partially uploaded in the last session for example
        var invalidFiles = this.value.filter(x => !x.fileId || x.fileId === "00000000-0000-0000-0000-000000000000");
        invalidFiles.forEach(file => this.remove(null, file));
    }

    async fileInputFilesChanged() {
        if (this.fileInputFiles.length > 0) {
            this.fileSelectionError = '';
        }

        for (let i = 0; i < this.fileInputFiles.length; i++) {
            const file = this.fileInputFiles.item(i);
            this.selectAndUploadFile(file);
        }

        if (this.fileInputFiles.length > 0) {
            this.fileInputFiles = null;
            this.fileInputValue = null;
        }
    }

    async selectAndUploadFile(file: File) {
        if (file.size > this.maxFileSize) {
            this.showFileSelectionError(`File too large: "${file.name}"`);
            return;
        }

        const dotIx = file.name.lastIndexOf('.');
        if (dotIx === -1) {
            this.showFileSelectionError(`Unsupported file type: "${file.name}"`);
            return;
        }

        const extension = file.name.substr(dotIx).toLowerCase();
        if (!this.acceptedFileTypes.split(', ').includes(extension)) {
            this.showFileSelectionError(`Unsupported file type: "${file.name}"`);
            return;
        }

        // check file hasn't already been uploaded
        const alreadyInValue = this.value.some(x => x.fileName === file.name && x.fileSize === file.size && x.fileType === file.type);
        if (alreadyInValue) {
            return;
        }

        const existing = this.existingFiles.find(x => x.fileName === file.name && x.fileSize === file.size && x.fileType === file.type);
        if (existing) {
            this.selectExisting(existing);
            return;
        }

        const doc = new Document();
        doc.fileName = file.name;
        doc.fileType = file.type;
        doc.fileSize = file.size;

        // moved these 2 lines back to before the upload as it broke progress indicator and didn't solve the intermittent issue with saving
        this.value.push(doc);
        this.existingFiles.push(doc);

        const fileId = await this.upload(file, doc);

        if (fileId) {
            trySetFileId();
        } else {
            this.remove(null, doc, false);
        }

        function trySetFileId() {
            // ensure add to collection has fired and returned with an id before setting file id
            setTimeout(async () => {
                if (!doc.id) {
                    trySetFileId();
                }
                else {
                    doc.fileId = fileId;
                }
            }, 100);
        }

        await validate(this.parent);
    }

    async upload(file: File, doc: Document): Promise<string> {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const result = await this.httpClient.createRequest('api/file/upload')
                .asPost()
                .withContent(formData)
                .withProgressCallback((e: ProgressEvent) => { this.uploadProgress(doc, e); })
                .withInterceptor({
                    request: async (request: RequestMessage) => {
                        const token = this.tokenService.getToken();
                        if (token) {
                            request.headers.add('Authorization', 'Bearer ' + token);
                        }
                        return request;
                    },
                    response: async (response: HttpResponseMessage) => {
                        if (response.statusCode === 503) {
                            window['offline'] = true;
                            window.location.reload();
                        }

                        return response;
                    },
                    responseError: async (response: HttpResponseMessage) => {
                        return response;
                    }
                } as Interceptor)
                .send();

            if (result.isSuccess) {
                return result.response.replace(/\"/g, "");
            } else {
                if (result.statusCode === 400) {
                    this.showFileSelectionError(result.response);
                }
            }

            return null;
        }
        catch {
            return null;
        }
    }

    uploadProgress(doc: Document, e: ProgressEvent) {
        doc.percentUploaded = Math.round((e.loaded / e.total) * 100);
    }

    async selectExisting(file: Document) {
        this.fileSelectionError = '';
        this.value.push(file);
        this.bindingSignaler.signal('refresh-existing');
        await validate(this.parent);
    }

    async remove(event: Event, doc: Document, removeError: boolean = true) {
        if (event) {
            event.stopPropagation();
        }

        if (removeError) {
            this.fileSelectionError = '';
        }

        const ix = this.value.indexOf(doc);
        this.value.splice(ix, 1);

        if (!doc.fileId || doc.fileId === "00000000-0000-0000-0000-000000000000") {
            const existingIx = this.existingFiles.indexOf(doc);
            this.existingFiles.splice(existingIx, 1);
        }

        this.bindingSignaler.signal('refresh-existing');
        await validate(this.parent);
    }

    async download(doc: Document) {
        this.fileSelectionError = '';

        if (doc.fileId) {
            this.documentDownloadService.download(doc.id);
        }
    }

    onDragOver(event: Event) {
        event.preventDefault();
        this.dragOver = true;
        return true;
    }

    onDragLeave(event: Event) {
        event.preventDefault();
        this.dragOver = false;
        return true;
    }

    onDrop(event: any) {
        this.dragOver = false;
        event.preventDefault();
        this.fileSelectionError = '';

        if (event.dataTransfer.items) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                if (event.dataTransfer.items[i].kind === 'file') {
                    const file = event.dataTransfer.items[i].getAsFile();
                    this.selectAndUploadFile(file);
                }
            }
        } else {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                this.selectAndUploadFile(event.dataTransfer.files[i]);
            }
        }

        return true;
    }

    getFileIcon(file: Document) {
        return Helpers.getFileIcon(file);
    }

    @computedFrom('existingFiles.length', 'value.length')
    get anyExistingFiles() {
        return this.existingFiles.some(x => !this.value.some(y => y.fileId === x.fileId && y.fileName === x.fileName));
    }

    @computedFrom('searchText')
    get showNoSearchResults() {
        return !!this.searchText && !this.existingFiles.some(x => !this.value.some(y => y.fileId === x.fileId && y.fileName === x.fileName) && x.fileName.toLowerCase().includes(this.searchText.toLowerCase()));
    }

    showFileSelectionError(message: string) {
        this.fileSelectionError += message + '\n';
        setTimeout(() => { this.fileSelectionError = ''; }, 5000);
    }

    toggleExistingKeydown(event: KeyboardEvent) {
        return Helpers.keypressEnterOrSpace(event, () => this.showExisting = !this.showExisting);
    }

    callUploadDialog() {
        const input = document.getElementById(this.fieldName as string);
        input.click();
    }
}

export class FilterValueConverter {
    toView(allItems: Document[], params: { selectedItems: Document[], search: string }) {
        const result = allItems.filter(x => !params.selectedItems.some(y => y.fileId === x.fileId && y.fileName === x.fileName));
        if (params.search === "" || params.search === undefined) return result;
        return result.filter((item) => item.fileName.toLowerCase().includes(params.search.toLowerCase()));
    }
}
