import { DialogController } from 'aurelia-dialog';
import { autoinject, observable } from 'aurelia-framework';
  
@autoinject()
export class PromptDialog {
    constructor(private controller: DialogController) { }

    title: string;
    @observable sharedComputer: boolean;

    activate(title: string) {
        this.title = title;
    }

    sharedComputerChanged(newValue: boolean) {
        this.controller.ok(newValue);
    }
}
